// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DroppableCell_root__1ITAK {\n  position: relative;\n  flex: 1 1 auto;\n  width: 0;\n  margin: 6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.DroppableCell_root__1ITAK:empty {\n  background: rgba(128, 128, 128, 0.15);\n}\n\n.DroppableCell_root__1ITAK::after {\n  content: '';\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: rgba(255, 0, 0, 0.25);\n  z-index: 1;\n  opacity: 0;\n  pointer-events: none;\n}\n\n.DroppableCell_isActive__1KHm3::after {\n  opacity: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "DroppableCell_root__1ITAK",
	"isActive": "DroppableCell_isActive__1KHm3"
};
module.exports = exports;
