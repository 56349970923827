import { defineMessages } from 'react-intl';

export default defineMessages({
  symbols: {
    id: 'cboard.components.Settings.Symbols.symbols',
    defaultMessage: 'Symbols',
  },
  downloadArasaac: {
    id: 'cboard.components.Settings.Symbols.downloadArasaac',
    defaultMessage: 'Download ARASAAC Symbols',
  },
  downloadArasaacSecondary: {
    id: 'cboard.components.Settings.Symbols.downloadArasaacSecondary',
    defaultMessage:
      'Downloads a package with all ARASAAC Symbols to be used in offline mode.',
  },
  symbolsArasaacHelp: {
    id: 'cboard.components.Settings.Symbols.symbolsArasaacHelp',
    defaultMessage:
      "Symbols download will allow you to have the symbols locally in your system so when you search for symbols to create a new element, you don't need an Internet connection.",
  },
  downloadArasaacDialogSubtitle: {
    id: 'cboard.components.Settings.Symbols.downloadArasaacDialogSubtitle',
    defaultMessage:
      'Do you want to download ARASAAC Symbols? The process will take some time.',
  },
  cancel: {
    id: 'cboard.components.Settings.Symbols.cancel',
    defaultMessage: 'Cancel',
  },
  download: {
    id: 'cboard.components.Settings.Symbols.download',
    defaultMessage: 'Download',
  },
  noConnection: {
    id: 'cboard.components.Settings.Symbols.noConnection',
    defaultMessage: 'No internet connection',
  },
  noConnectionDialogDesc: {
    id: 'cboard.components.Settings.Symbols.noConnectionDialogDesc',
    defaultMessage:
      'No internet connection. You need to be connected to the internet to download symbols.',
  },
});
