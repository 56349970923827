// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DefaultBoardSelector_dialogBar__r3hu8 {\n  color: #fff;\n  background-color: #3f51b5;\n  display: flex;\n  justify-content: space-between;\n}\n\n.DefaultBoardSelector_isDark__1to1h .DefaultBoardSelector_dialogBar__r3hu8 {\n  background-color: #78909c;\n}\n\n.DefaultBoardSelector_dialogBar__r3hu8 > h2 {\n  width: 100%;\n}\n\nbutton.DefaultBoardSelector_closeButton__3im2T {\n  padding-right: 0;\n}\n\n.DefaultBoardSelector_close__JyokW {\n  color: #fff;\n}\n\n.DefaultBoardSelector_header__2cMSX {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n.DefaultBoardSelector_card__V8BMW {\n  width: 100%;\n  height: 100%;\n}\n\n.DefaultBoardSelector_cardContent__36blR:last-child {\n  padding-bottom: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"dialogBar": "DefaultBoardSelector_dialogBar__r3hu8",
	"isDark": "DefaultBoardSelector_isDark__1to1h",
	"closeButton": "DefaultBoardSelector_closeButton__3im2T",
	"close": "DefaultBoardSelector_close__JyokW",
	"header": "DefaultBoardSelector_header__2cMSX",
	"card": "DefaultBoardSelector_card__V8BMW",
	"cardContent": "DefaultBoardSelector_cardContent__36blR"
};
module.exports = exports;
