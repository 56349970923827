// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PremiumRequiredModal_content__2-wCv {\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  margin: 2em 3em;\n}\n\n.PremiumRequiredModal_dialogText__2pB6i {\n  padding-bottom: 1em;\n}\n\n@media (max-width: 480px), (max-height: 400px) {\n  .PremiumRequiredModal_content__2-wCv {\n    margin: 2em 1em;\n  }\n\n  .PremiumRequiredModal_content__2-wCv h3 {\n    font-size: 2em;\n  }\n  .PremiumRequiredModal_content__2-wCv h6 {\n    font-size: 1.2em;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"content": "PremiumRequiredModal_content__2-wCv",
	"dialogText": "PremiumRequiredModal_dialogText__2pB6i"
};
module.exports = exports;
