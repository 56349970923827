// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Grid_root__1N2_P {\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n  /* scroll-snap-type: y mandatory; */\n}\n\n.Grid_grid__1byUc {\n  flex-shrink: 0;\n  /* scroll-snap-align: start; */\n}\n", ""]);
// Exports
exports.locals = {
	"root": "Grid_root__1N2_P",
	"grid": "Grid_grid__1byUc"
};
module.exports = exports;
