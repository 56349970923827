// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Row_root__YuDi2 {\n  display: flex;\n  flex: 1 1;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "Row_root__YuDi2"
};
module.exports = exports;
