// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GridBase_root__3BvaR {\n  box-sizing: border-box;\n  height: 100%;\n  width: 100%;\n  padding: 6px;\n  display: flex;\n  flex-direction: column;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "GridBase_root__3BvaR"
};
module.exports = exports;
