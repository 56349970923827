// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DraggableItem_root__28swz {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  right: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "DraggableItem_root__28swz"
};
module.exports = exports;
